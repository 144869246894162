<template>
  <div class="front-cont">
    <div class="img-bg row f-page-bg" style="opacity: 0.95">
      <div class="f-faq-content" v-if="loaded">
        <div class="p-3 p-md-4 mt-6 mt-md-4 mb-5 mb-md-4 bg-white faq-box">
          <div class="mx-3">
            <h1 class="section-title text-dark mb-3">
              {{ formData.pagetitle }}
            </h1>

            <el-collapse>
              <el-collapse-item
                v-for="item in List"
                :title="$h.formatName(item, 'Title')"
                :name="item.ItemKey"
                :key="item.ItemKey"
              >
                <div class="px-3 pt-2" v-html="$h.formatName(item, 'Description')"></div>
              </el-collapse-item>
            </el-collapse>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";

export default {
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    let formData = ref({});
    let List = ref([]);

    onMounted(async () => {
      loadItems();
    });

    const loadItems = async () => {
      let faq = await get("/cms/get/faq.json");
      formData.value = faq?.PageData;

      List.value = _.map(faq?.List, (r) => {
        return {
          ItemKey: r.id,
          Title: r.pagetitle,
          Description: r.content,
        };
      });

      loaded.value = true;
    };

    return { loaded, formData, List, loadItems };
  },
};
</script>
<style>
.f-faq-content {
  width: 100%;
  min-height: 90vh;
  display: flex;
  align-items: center;
  justify-content: center;

  padding-left: 2rem;
  padding-right: 2rem;
}
.img-bg {
  background-size: cover;
  min-height: 90vh;
}
.faq-box {
  border-radius: 8px;
  width: 100%;
  opacity: 0.92;
}
.faq-box .el-collapse {
  border-top: 0px solid #fff;
  border-bottom: 0px solid #fff;
}
.faq-box .el-collapse-item__wrap {
  border-bottom: 0px solid #fff;
}
.faq-box .el-collapse-item__header {
  background-color: #fff;
  opacity: 1;
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 0px 1rem;
  margin-bottom: 7px;
  font-size: var(--f-size-fr-p);
  height: var(--fr-input-height);
  line-height: var(--l-height-head-ttl);
}
.faq-box .el-collapse-item__arrow {
  margin: 0 0px 0 auto;
  font-weight: bold;
}

@media (max-width: 900px) {
  .faq-box .el-collapse-item__header {
    height: unset;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
