<template>
  <div class="front-cont">
    <div class="row">
      <div v-if="loaded" class="f-tc-content f-page-bg pt-5 pt-md-0 pb-3 pb-md-0">
        <div class="px-3 px-md-7 py-4 py-md-6">
          <div class="bg-white p-4 p-md-5" style="border-radius: 8px">
            <h1 class="section-title text-dark mb-4">
              {{ TermsData.pagetitle }}
            </h1>

            <section v-html="TermsData.content"></section>
          </div>
        </div>
      </div>
    </div>

    <el-backtop class="back-top-mark">
      <img src="/img/front/to_top.png" class="w-100" />
    </el-backtop>
  </div>
</template>
<script>
import { onMounted, onUpdated, ref, reactive, computed } from "vue";
import { useRoute } from "vue-router";
import { post, get, isValidResponse } from "@/utils/axios";
import { helpers } from "@/utils/helpers";
import { router } from "@/utils/router";

export default {
  setup() {
    const loaded = ref(false);
    const route = useRoute();
    let formData = reactive({});
    let TermsData = ref({});

    onMounted(async () => {
      TermsData.value = await get("/cms/get/privacy.json");
      loaded.value = true;
    });

    return { loaded, formData, TermsData };
  },
};
</script>
<style>
.f-tc-content p,
.f-tc-content strong {
  font-family: AcuminConcept !important;
  color: #707070;
}

@media (max-width: 900px) {
}
</style>
